export function convertTo12HourFormat(time){
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = time.split(':').map(Number);
    
    // Determine whether it's AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';
    
    // Convert to 12-hour format
    let hour12 = hours % 12 || 12; // Convert 0 to 12 for midnight
    
    // Return the formatted time string
    return `${hour12}${period}`;
}