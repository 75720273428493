import { useContext } from 'react';
import { convertTo12HourFormat } from '../../utils/Times'
import { ViewingsContext } from '../../contexts/ViewingsContext';

export default function Viewing(props) {
    const { deleteViewing } = useContext(ViewingsContext)

    const DATE_OPTIONS = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

    const FORMATTED_DATE = new Date(props.viewing.date).toLocaleDateString('en-GB', DATE_OPTIONS);
    const START_TIME = props.viewing.startTime ? convertTo12HourFormat(props.viewing.startTime) : 'Start time not specified';
    const FINISH_TIME = props.viewing.finishTime ? convertTo12HourFormat(props.viewing.finishTime) : null;

    return (
        <div className="listing">
            <div className="listing-header">
                <h2 className="listing-address">{props.viewing.address}</h2>
                <h3 className="listing-date">{FORMATTED_DATE}</h3>
                <h3 className="listing-time">
                    {FINISH_TIME ? `${START_TIME} - ${FINISH_TIME}` : `From ${START_TIME}`}
                </h3>
            </div>
            
            <div className="listing-content">
                <div className="listing-description">
                    {props.viewing.description}
                </div>
            </div>

            <div className="listing-footer">
                <button className='btn-base blue' onClick={() => deleteViewing(props.viewing.orderId)}>Mark as viewed</button>
            </div>
        </div>
    )
}