import { useContext } from 'react'

import Viewing from './Viewing'

import { ViewingsContext } from '../../contexts/ViewingsContext'

export default function Viewings (props) {
    
    const { viewings } = useContext(ViewingsContext)

    const TOTAL_VIEWINGS = viewings.length || 0;

    const LISTING_LABEL = viewings.length === 1 ? 'Viewing' : 'Viewings'

    return (
        <div className="listings-container">
            <h2 className="listings-label">{TOTAL_VIEWINGS} {LISTING_LABEL} Saved</h2>
            {
                viewings.length > 0 ?
                <div> 
                    {viewings.map((viewing, index) => {
                        return ( <Viewing key={index} viewing={viewing} /> )
                    })}   
                </div>
                
                :
                
                <div>
                    <p className='font-heading'>You haven't added any viewings to your list.</p>
                    <p className='font-heading'>Add some viewings to get started.</p>
                </div>
            }
        </div>
    )
}