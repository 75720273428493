const Reducer = (state, action) => {
    const { type, payload } = action;
  
    switch(type) {
      case 'DELETE_VIEWING':
        return {
          ...state,
          viewings: state.viewings.filter(viewing => viewing.orderId !== payload)
        };
      case 'ADD_VIEWING':
        return {
          ...state,
          viewings: [payload, ...state.viewings]
        };
      default:
        return state;
    }
  };
  
  export default Reducer;
  