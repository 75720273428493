import Listing from "./Listing";

export default function Listings (props) {

    const TOTAL_LISTINGS = props.listings.length;

    const LISTING_LABEL = props.listings.length === 1 ? 'Listing' : 'Listings'

    return (
        <div className="listings-container">
            <h2 className="listings-label">{TOTAL_LISTINGS} {LISTING_LABEL} Found</h2>
                {props.listings.map(listing => {
                    return <Listing 
                                listing={listing} 
                                key={listing.orderId} 
                            />
                })}
        </div>
    )
}