import { useContext } from 'react';
import { convertTo12HourFormat } from '../../utils/Times'
import { ViewingsContext } from '../../contexts/ViewingsContext'

export default function DetailsModal (props) {
    
    const { addViewing } = useContext(ViewingsContext)

    const newViewing = {
        address: props.listing.address,
        date: props.listing.date,
        description: props.listing.description,
        startTime: props.listing.startTime,
        finishTime: props.listing.finishTime,
        orderId: props.listing.orderId,
    }

    function addListingToViewing(){
        addViewing(newViewing)
    }


    const DATE_OPTIONS = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

    const FORMATTED_DATE = new Date(props.listing.date).toLocaleDateString('en-GB', DATE_OPTIONS);
    const START_TIME = props.listing.startTime ? convertTo12HourFormat(props.listing.startTime) : 'Start time not specified';
    const FINISH_TIME = props.listing.finishTime ? convertTo12HourFormat(props.listing.finishTime) : null;

    return (
        <div className="details-modal">
            <div className="details-modal-header">
                <h2 className="listing-address">{props.listing.address}</h2>
                <h3 className="listing-date">{FORMATTED_DATE}</h3>
                <h3 className="listing-time">
                    {FINISH_TIME ? `${START_TIME} - ${FINISH_TIME}` : `From ${START_TIME}`}
                </h3>
            </div>
            
            <div className="details-modal-content">
                <div className='listing-description'>
                    {props.listing.description}
                </div>
            </div>
            
            <div className="details-modal-footer">
                <button className='btn-base blue' onClick={() => addListingToViewing()}>Add to viewings</button>
                <button className='btn-base red' onClick={props.onClose}>Close</button>
            </div>
        </div>
    )
}