import React, { useReducer, createContext } from 'react';
import Reducer from './Reducer';

const initialState = { viewings: [] };

export const ViewingsContext = createContext(initialState);

export const ViewingsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    const deleteViewing = orderId => {
        dispatch({ type: 'DELETE_VIEWING', payload: orderId });
    };

    const addViewing = viewing => {
        // Look for an existing viewing by orderId
        const existingViewing = state.viewings.findIndex(v => v.orderId === viewing.orderId);

        // If a viewing with the same orderId exists, don't call the ADD_VIEWING reducer
        if (existingViewing >= 0) {
            console.log('Viewing already exists in context.')
            return;
        }

        dispatch({ type: 'ADD_VIEWING', payload: viewing });
    };

    return (
        <ViewingsContext.Provider value={{ viewings: state.viewings, deleteViewing, addViewing }}>
            {children}
        </ViewingsContext.Provider>
    );
};
