import Logo from '../../assets/logo-blue.png'

export default function Header (props) {
    return (
        <header className="w-full h-fit bg-white border-b">
            <div className="wrapper flex flex-row justify-between items-center">
                <img className="logo" src={Logo} alt='ACM' />
                <h1 className='page-title font-heading font-bold text-2xl'>{props.pageTitle}</h1>
            </div>
        </header>   
    )
}