import React, {useState} from 'react'

import Listings from '../Listings/Listings'
import Viewings from '../Viewings/Viewings'

export default function Sidebar (props) {
    const [activeComponent, setActiveComponent] = useState('Listings');

    const handleClick = (component) => {
        setActiveComponent(component);
    };

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <button
                    className={activeComponent === 'Listings' ? 'active' : ''}
                    onClick={() => handleClick('Listings')}
                >
                    Listings
                </button>
                <button
                    className={activeComponent === 'Viewings' ? 'active' : ''}
                    onClick={() => handleClick('Viewings')}
                >
                    Viewings
                </button>
            </div>
            <div className="sidebar-content">
                {activeComponent === 'Listings' ? <Listings listings={props.listings} /> : <Viewings />}
            </div>
        </div>
    )
}