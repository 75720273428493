import React from 'react'

import {
    MemoryRouter as Router,
    Routes,
    Route
} from 'react-router-dom'

import Home from './views/Home';

import { ViewingsProvider } from './contexts/ViewingsContext'

export default function App() {
  return (
    <ViewingsProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
          </Routes>
        </Router>
      </div>
    </ViewingsProvider>
  );
}